/* global gapi */
/* global gapiPromise */
import Cookies from 'universal-cookie';
import * as BrowserUrls from './BrowserUrls'
import * as Utils from './Utils';


export const signOut = (autoSignOut) => {
    gapi.load('auth2', function() {
        gapi.auth2.init().then(function(){
            var auth2 = gapi.auth2.getAuthInstance();
            if(autoSignOut){
                    auth2.signOut().then(function () {
                    console.log('User signed out automatically.');
                });
            }else{
                auth2.signOut().then(function () {
                    console.log('User signed out.');
                    window.location.href = BrowserUrls.getLoginPageUrl();
                });
            }
        })
    });
}
