import * as APIUrls from './APIUrls'
import mixpanel from 'mixpanel-browser';

export const isDevEnv = () => {
    if(process.env.NODE_ENV === 'development')
        return true;
    else return false;
}

export const track = (featureUsage) => {
    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(featureUsage)
    };

    fetch(APIUrls.getUsageTrackAPIURL(), requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

export const initMixpanel = () => {
    if(process.env.NODE_ENV === 'production')
      mixpanel.init('bd31646c2c8681d1e0a0d3aab19a4826',{
        debug: true,
        persistence: "localStorage",
      }); 
    else mixpanel.init('d6a56d4525028cc8d6f415da3c7e5370', {debug: true,
        persistence: "localStorage"
    }); 
}