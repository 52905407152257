function getBaseURL(){
    if(process.env.NODE_ENV === 'development')
        return 'http://localhost:8080';
    else return "";
}

export const getThreadStatsUrl = (accountId, showThreadType, startTime = 0, endTime = 30) => {
    if(showThreadType !== 'opened' && showThreadType !== 'unopened') showThreadType = 'all'
    return `${getBaseURL()}/api/v1/accounts/${accountId}/stats/${showThreadType}?starttime=${startTime}&endtime=${endTime}`;
}

export const getUsageTrackAPIURL = () => {
    return `${getBaseURL()}/api/v1/usage/track`;
}

export const getAccountInfoURL = () => {
    return `${getBaseURL()}/api/account/signInToAccount`;
}