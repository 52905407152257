import React from 'react';

import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import * as Utils from '../common/Utils';
import * as AppConsts from '../common/AppConsts';
import mixpanel from 'mixpanel-browser';

export function AccountView(props){
    if(!props.account) return null;
    mixpanel.track("View Account Page", {
    });
    return(
        <Container style={{textAlign: 'left', marginLeft: '20rem', marginTop: '8rem', paddingBottom: '3rem', paddingLeft: '7rem'}}>
            <Row>
                <Col style={{textAlign:'right', fontWeight: '700'}} md={3}>Email:</Col><Col md={9}>{props.account.email}</Col> 
            </Row>
            <Row style={{marginTop: '1rem '}}>
                <Col style={{textAlign:'right', fontWeight: '700'}} md={3}>Current Plan:</Col>
                <Col style={{color:'#d41f1f'}} md={9}>
                    <span className="mr-3">{props.account.sku ? props.account.sku.description: 'Free Limited Version'}</span>
                    <span>{!props.account.sku && <a style={{fontSize:'.9rem',fontWeight:'700',textDecoration: 'underline'}} href="/pricing.html">Upgrade Now</a>} </span>
                </Col> 
            </Row>
            <Row style={{marginTop: '1rem '}}>
                <Col style={{textAlign:'right', fontWeight: '700'}} md={3}>Real-time Notifications:</Col><Col md={9}>{props.account.hasRTNotificationEnabled === 1 ? 'Enabled': 'Disabled'}</Col> 
            </Row>
            <Row style={{marginTop: '1rem '}}>
                <Col style={{textAlign:'right', fontWeight: '700'}} md={3}>Timezone:</Col><Col md={9}>{props.account.timeZone ? props.account.timeZone: ''}</Col> 
            </Row>
            <Row style={{marginTop:'3rem'}}>
                <Col md={10}>
                    {!props.account.sku &&
                        <Alert variant="primary">
                            You are on limited Free version, where you can track <b>50 emails/month</b>. Upgrade now to get unlimited tracking and 
                            other Pro features (Link click tracking, Priority support, Multiple Email accounts and more). <br></br>
                            <Alert.Link className="mt-5" href="/pricing.html">Upgrade Now<i className="fa fa-long-arrow-right ml-2"></i></Alert.Link>
                        </Alert>
                    }
                </Col>
                <Col md={2}></Col>
            </Row>
        </Container>
    )
}