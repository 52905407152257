import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import emtLogo from '../../assets/emt-logo-blue.png';
import '../../App.css';
import * as SimpleAccount from '../../common/SimpleAccount';

export function TopNavbar(props){
    function handleSignOut(){
        SimpleAccount.signOut();
    }

    return(
        <Navbar className="Top-navbar" expand="lg" variant="light">
            <Navbar.Brand href="/"><img src={emtLogo} width="140px"/></Navbar.Brand>
            
            <div className="d-none d-md-block">
                <Nav className="ml-auto">
                    {props.account && !props.account.sku &&
                        <Nav.Item className="mr-5">
                            <Nav.Link href="/pricing.html" className="nav-link-red">Upgrade Now to unlock all pro features<i className="ml-3 fa fa-long-arrow-right"></i></Nav.Link>
                        </Nav.Item>
                    }
                    <Nav.Item className="mr-4">
                        <Nav.Link href="/pricing.html">Pricing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-4">
                        <Nav.Link href="/faq.html">FAQ</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-4">
                        <Nav.Link onClick={handleSignOut} eventKey="link-1">Switch Account</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-4">
                        <Nav.Link onClick={handleSignOut} eventKey="link-1">Sign Out</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </Navbar>
    )
}