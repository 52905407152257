import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import Moment from 'react-moment';
import 'moment-timezone';

import '../App.css';

const styles = {
    subject: {
        fontSize: "1rem",
        color: "#7695a5"
    }, 
    toEmail: {
        
    },
    sentTime: {
        fontSize: ".9rem",
        color: "#666"
    },
    openDetails: {
        fontSize: ".9rem",
        color: "#0000ff"
    },
    openStyle: {
        color: "#dc3545",
        width: "60px",
        backgroundColor: "#ffeaea",
        border: "1px solid #feccd1",
        fontSize: "0.80rem",
        padding: ".25rem .255rem",
        fontWeight: 500
    },
    sentStyle: {
        color: '#427793',
        width: '60px',
        backgroundColor: '#eee',
        border: '1px solid #ddd',
        fontSize: '0.80rem',
        padding: '.25rem .255rem',
        fontWeight: 500
    },
    traceObjContainer: {
        backgroundColor: "#fcfcfc",
        width: "85%",
        marginBottom: "1rem",
        marginTop: "1rem",
        paddingBottom: ".4rem",
        traceObjRow: {
            marginBottom: ".6rem",
            paddingTop: ".6rem",
            color: "#666",
            fontSize: ".9rem",
            deviceIcon: {
                fontSize: ".8rem",
                color: '#999'
            },
            linkToolTip: {
                color: '#2980b9',
                borderBottom: '1px dotted #2980b9',
                cursor: 'pointer'
            }
        },
        locationTextStyle: {
            fontSize: ".8rem"
        }
    },
    cardStyle: {
        marginBottom: '.8rem',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        cardBody: {
            padding: '.8rem',
            col: {
                paddingLeft: 0,
                paddingRight: 0
            }, 
            sentCol: {
                paddingLeft: 0,
                paddingRight: 0,
                textAlign: 'right'
            }, 
            timeSmall: {
                fontSize: '.8rem',
                color: '#888'
            }
        }
    }
}
export function ActivityCard(props){
    let emailRecipientListDisplayStr;
    let [showTraceObjContainer, setShowTraceObjContainer] = useState(false);

    if(props.threadStat.sentAddressesForDisplay && props.threadStat.sentAddressesForDisplay.length > 0){
        emailRecipientListDisplayStr = props.threadStat.sentAddressesForDisplay[0];
        if(props.threadStat.sentAddressesForDisplay.length > 1)
            emailRecipientListDisplayStr = `${emailRecipientListDisplayStr} & ${props.threadStat.sentAddressesForDisplay.length-1} more`
    } else {
        return null;
    }

    if(props.threadStat.domainForLogoDisplay) {

    }

    function handleExpandTraceDetails(e){
        e.preventDefault();
        setShowTraceObjContainer(!showTraceObjContainer);
    }

    return(
        <Card style={styles.cardStyle}>
            <Card.Body>
                <Container>
                    <Row>
                        <Col md={1} className="d-none d-md-block p-0">
                            {props.threadStat.domainForLogoDisplay &&
                                <img src={`https://www.google.com/s2/favicons?domain=${props.threadStat.domainForLogoDisplay}`} width="50%" alt="user icon"></img>
                            }
                            {!props.threadStat.domainForLogoDisplay &&
                                <i class="fa fa-user-circle-o" style="font-size:1.5rem;color:#eee;"></i>
                            }
                        </Col>
                        <Col md={7}>
                            {emailRecipientListDisplayStr}
                            <div style={styles.subject}>{props.threadStat.subject}</div>
                        </Col>
                        <Col md={4} style={{textAlign: "right"}}>
                            <span style={styles.sentTime}>Sent: {props.threadStat.lastSentTimePretty}</span>
                            <div style={styles.cardStyle.cardBody.timeSmall}>
                                <Moment local format="LLLL">
                                    {props.threadStat.lastSentTime}
                                </Moment>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container style={{marginTop: "1.6rem"}}>
                    <Row>
                        <Col md="1"></Col>
                        <Col md="7">
                            {props.threadStat.numOpens > 0 &&
                                <div style={styles.openDetails}><a style={{cursor: 'pointer'}} onClick={handleExpandTraceDetails}>
                                        {props.threadStat.numOpens} opens. {props.threadStat.numClicks > 0 && `${props.threadStat.numClicks} clicks.`} Last {props.threadStat.lastOpenTimePretty}. 
                                        <i className={`fa ml-2 ${showTraceObjContainer ? 'fa-angle-down' : 'fa-angle-right'}`}></i>
                                    </a>
                                </div>
                            }
                        </Col>
                        <Col md="4" style={{textAlign: "right"}}><span><Badge variant="secondary" style={props.threadStat.openedOrSent == 'OPEN' ? styles.openStyle : styles.sentStyle}>{props.threadStat.openedOrSent}</Badge></span></Col>
                    </Row>
                </Container>
                {props.threadStat.numOpens > 0 &&
                    <TraceObjectContainer show={showTraceObjContainer} traceObjects={props.threadStat.traceObjects}/>
                }
            </Card.Body>
        </Card>
    )
}

function TraceObjectContainer(props){
    if(!props.show || !props.traceObjects) return null;
    const traceObjs = props.traceObjects.map((traceObject, index) =>
        <Row style={styles.traceObjContainer.traceObjRow} key={index}>
            <Col md="6">
                {traceObject.traceType == 0 &&
                    <>
                        <span style={{marginRight: ".6rem"}}>
                            <i className="fa fa-envelope-open-o mr-2" ></i>
                            {traceObject.traceString}
                        </span>
                        <div style={styles.cardStyle.cardBody.timeSmall}>
                            <Moment local format="LLLL">
                                {traceObject.time}
                            </Moment>
                        </div>
                    </>
                }
                {traceObject.traceType == 1 &&
                    <>
                        <span style={{marginRight: ".6rem"}}>
                            <i className="fa fa-link mr-2" ></i>
                            Clicked a {' '} 
                        <OverlayTrigger placement="top" overlay={
                            <Tooltip>
                                {traceObject.linkStr}
                            </Tooltip>}>
                            <span style={styles.traceObjContainer.traceObjRow.linkToolTip} data-effect="solid" data-place="right" title={traceObject.linkStr}>link</span> 
                        </OverlayTrigger>
                        {' '}{traceObject.traceString}</span>
                        <div style={styles.cardStyle.cardBody.timeSmall}>
                            <Moment local format="LLLL">
                                {traceObject.time}
                            </Moment>
                        </div>
                    </>
                }
                
            </Col>
            <Col md="6" style={{textAlign: "right"}}>
                <span style={{fontSize: '.8rem'}}>{traceObject.traceLocation} {'   '}</span>
                <i style={styles.traceObjContainer.traceObjRow.deviceIcon} className={`fa ${traceObject.traceDevice == 0 ? 'fa-desktop' : 'fa-mobile'}`}></i>
            </Col>
        </Row>
	);
    return (
        <Container style={styles.traceObjContainer}>
            {traceObjs}
        </Container>
    )
}

function renderTooltip(props) {
    return <Tooltip {...props}></Tooltip>;
}