export const getDashboardUrl = (accountId, startTime = 0, endTime = 30) => {
    return `/accounts/${accountId}/dashboard`;
}

export const getStatsUrl = (accountId, showThreadType = 'all', startTime = 0, endTime = 30) => {
    if(showThreadType !== 'opened' && showThreadType !== 'unopened') showThreadType = 'all'
    return `/accounts/${accountId}/stats/${showThreadType}`;
}

export const getAccountPageUrl = (accountId) => {
    return `/accounts/${accountId}`;
}

export const getLoginPageUrl = () => '/signin.html';