import React from 'react';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';

import '../../App.css';
import * as BrowserUrls from '../../common/BrowserUrls'

const sidebarItemContainer = {
    marginTop: '2rem',
};

const WideSidebar = styled.div`
    height: 100%;
    width: 18rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding-top: 2rem;
    padding-left: 3rem;
    margin-top: 3rem;
    text-align: left;
    font-weight: 700;

    @media only screen and (max-width: 600px) {
      display: none;
    }
`;

export function LeftNavbar(props){
    let allMailsUrl = BrowserUrls.getStatsUrl(props.accountId);
    let openedMailsUrl = BrowserUrls.getStatsUrl(props.accountId, 'opened');
    let unOpenedMailsUrl = BrowserUrls.getStatsUrl(props.accountId, 'unopened');
    let accountPageUrl = BrowserUrls.getAccountPageUrl(props.accountId);

    return(
        <WideSidebar>
            <Nav variant="pills" style={sidebarItemContainer} activeKey={props.selectedNavMenuItem} className="flex-column" onSelect={selectedKey => props.onNavMenuItemChange(`${selectedKey}`)}>
                <Nav.Item><Nav.Link eventKey="all" href={allMailsUrl}><i className="fa fa-bolt mr-4" aria-hidden="true"></i>All Activities</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="opened" href={openedMailsUrl}><i className="fa fa-envelope-open-o mr-3" style={{fontSize: '.85rem'}} aria-hidden="true"></i>Opened Emails</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="unopened" href={unOpenedMailsUrl}><i className="fa fa-paper-plane mr-3" style={{fontSize: '.85rem'}}  aria-hidden="true"></i>Unopened Emails</Nav.Link></Nav.Item>
                <hr/>
                <Nav.Item style={{marginTop: '2rem'}}><Nav.Link eventKey="account" href={accountPageUrl}><i className="fa fa-user-o mr-3" style={{fontSize: '.85rem'}}  aria-hidden="true"></i>My Account</Nav.Link></Nav.Item>
            </Nav>
        </WideSidebar>
    )
}