/* global gapi */
/* global gapiPromise */
import React, { useState, useParams, useEffect }  from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import Cookies from 'universal-cookie';
import mixpanel from 'mixpanel-browser';

import logo from './logo.svg';
import './App.css';
import { TopNavbar } from './components/common/TopNavbar';
import { LeftNavbar } from './components/common/LeftNavbar';
import { Dashboard } from './components/Dashboard';
import { AccountView } from './components/AccountView';
import * as BrowserUrls from './common/BrowserUrls';
import * as SimpleAccount from './common/SimpleAccount';
import * as Utils from './common/Utils';
import * as APIUrls from './common/APIUrls';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';

function App() {
    let [accountId, setAccountId] = useState(null);
    let [email, setEmail] = useState(null);
    let [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false); 
    let [account, setAccount] = useState(null);

    Utils.initMixpanel();
    const routes = [
        {
            path: "/accounts/:accountId/dashboard",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="all"
                wideWorkSpace={true}
                account={account}/>,
            main: () => <Dashboard accountId={accountId} email={email}/>,
        },
        {
            path: "/accounts/:accountId/stats/all",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="all"
                wideWorkSpace={true}/>,
            main: () => <Dashboard accountId={accountId} email={email}/>,
        },
        {
            path: "/accounts/:accountId/stats/opened",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="opened"
                wideWorkSpace={true}/>,
            main: () => <Dashboard showThreadType="opened" accountId={accountId} email={email}/>,
        },
        {
            path: "/accounts/:accountId/stats/unopened",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="unopened"
                wideWorkSpace={true}/>,
            main: () => <Dashboard showThreadType="unopened" accountId={accountId} email={email}/>,
        },
        {
            path: "/accounts/:accountId",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="account"
                wideWorkSpace={true}/>,
            main: () => <AccountView account={account}/>,
        },
        {
            path: "/app",
            exact: true,
            sidebar: () => <LeftNavbar 
                accountId={accountId}
                selectedNavMenuItem="all"
                wideWorkSpace={true}/>,
            main: () => <Dashboard accountId={accountId} email={email}/>,
        }
    ];

    useEffect(() => {
        const cookies = new Cookies();
        let accountIdFromCookie = cookies.get('accountId');
        let emailFromCookie = cookies.get('email');
        let envFromCookie = cookies.get('env');
        let isDevEnv = Utils.isDevEnv() || (envFromCookie && envFromCookie === 'dev');

        if(accountIdFromCookie && emailFromCookie){
            if(isDevEnv){
                setAccountId(accountIdFromCookie);
                setEmail(emailFromCookie);
                setIsLoaded(true);
                getAccountInfo(emailFromCookie);
            } else {
                //let history = useHistory();
                gapiPromise.then(function(){
                    // will be executed after gapi is loaded
                    gapi.load('auth2', function(){
                        gapi.auth2.init().then(function () {
                            if(gapi.auth2.getAuthInstance().currentUser.get().isSignedIn()){
                                let email = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
                                let name = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName();
                                if(email === emailFromCookie) {
                                    setAccountId(accountIdFromCookie);
                                    setEmail(email);
                                    setIsLoaded(true);
                                    getAccountInfo(email);
                                } else {
                                    window.location.href = BrowserUrls.getLoginPageUrl();
                                }
                                
                            }else{
                                window.location.href = BrowserUrls.getLoginPageUrl();
                            }
                        });
                    });
                });
            }
        } else {
            window.location.href = BrowserUrls.getLoginPageUrl();
        }
    }, [accountId]);

    function getAccountInfo(email){
        //Get account info
        fetch(APIUrls.getAccountInfoURL(),{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: email})
            }
        ).then((res) => {
            if(res.ok){
                return res.json();
            }
            throw res;
        }).then(
            (accountData) => {
                setAccount(accountData.data);
                console.log(accountData.data);
                mixpanel.identify(accountData.data.email);
                mixpanel.register({
                    id: accountData.data.accountId,
                    email: accountData.data.email,
                    created: accountData.data.createdDate,
                    name: accountData.data.fullName,
                    accountLocked: accountData.data.accountLocked,
                    sku: accountData.data.sku ? accountData.data.sku.code : null
                })
                mixpanel.people.set({ 
                    distinct_id: accountData.data.accountId,
                    email: accountData.data.email,
                    created: accountData.data.createdDate,
                    name: accountData.data.fullName,
                    accountLocked: accountData.data.accountLocked,
                    sku: accountData.data.sku ? accountData.data.sku.code : null
                });
            },
            (error) => {
                console.log(error);
            }
        )
    }

    return (
        <Router>
            
            {isLoaded &&
                <div>
                    <TopNavbar account={account}/>
                    {/*Sidebar Routes*/}
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                children={<route.sidebar />}
                            />
                        ))}
                    </Switch>
                    {/*Main section Routes*/}
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                children={<route.main />}
                            />
                        ))}
                    </Switch>
                    {/*<SubscribeModal show={account && account.accountLocked === 1} onHide={() => console.log('')}/>*/}
                </div>
            }
        </Router>
    );
}

function SubscribeModal(props){
    return(
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Body className="text-center p-5">
                <h5 className="">You have reached your free tracking limit of 50 emails this month</h5>
               
                <h4 className="mt-5">
                    <a href="https://app.mailerplex.com/pricing.html"><span className="link-color">Try one month for $5</span></a>
                </h4>
                <p className="mb-4 grey-font">You can <span className="bold-font">cancel anytime</span></p>
                <Button href="https://app.mailerplex.com/pricing.html" classname="btn btn-primary"><span className="bold-font">Subscribe Now</span></Button>
            </Modal.Body>
        </Modal>
    )
}

export default App;
